import fetch from '../utils/fetch'

export function getOrderList(query) {
  return fetch({
    url: '/mind/orders',
    method: 'get',
    params: query
  })
}

export function orderExport(query) {
  return fetch({
    url: '/mind/orders/exportdata',
    method: 'get',
    params: query
  })
}

export function updateDeal(order_id, query) {
  return fetch({
    url: '/mind/deal/' + order_id,
    method: 'put',
    params: query
  })
}

export function getOrderDetail(order_id) {
  return fetch({
    url: '/mind/order/' + order_id,
    method: 'get'
  })
}

export function updateRemarks(order_id, query) {
  return fetch({
    url: '/mind/remarks/' + order_id,
    method: 'put',
    params: query
  })
}

export function updateMind(order_id, query) {
  return fetch({
    url: '/mind/update/' + order_id,
    method: 'put',
    params: query
  })
}